import { FormProvider, useForm, UseFormProps } from "react-hook-form";

declare interface SearchFormProps {
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (data: any) => void;
}

export default function SearchForm({
  children,
  className = "",
  onChange,
  ...props
}: SearchFormProps & UseFormProps) {
  const form = useForm({ mode: "onChange", ...props });
  form.watch((data) => onChange(data));

  return (
    <FormProvider {...form}>
      <form className={className}>{children}</form>
    </FormProvider>
  );
}
