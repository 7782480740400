import { useCallback, useMemo, useState } from "react";

import { CrossSmall, MagnifyingGlassSmall } from "./icons";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce(fn: (...args: any) => void, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

export declare interface SearchProps {
  live?: boolean;
  onSearch?: (value: string) => void;
  onSubmit?: (value: string) => void;
  placeholder?: string;
  resetButton?: boolean;
  value?: string;
}

export default function Search({
  live = false,
  onSearch = () => null,
  onSubmit = () => null,
  placeholder = "",
  resetButton = false,
  value = "",
}: SearchProps) {
  const [focus, setFocus] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  const debouncedOnSearch = useMemo(() => debounce(onSearch), [onSearch]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(event.target.value);
      if (live) {
        debouncedOnSearch(event.target.value);
      }
    },
    [live, debouncedOnSearch],
  );

  return (
    <div>
      <form
        className="flex"
        onSubmit={(e) => {
          e.preventDefault();
          onSearch(internalValue || value);
        }}
      >
        <input
          className="text-ellipsis rounded-sm rounded-r-none border border-r-0 border-graphite p-2 pr-0 text-iron outline-none focus:border-iron"
          onBlur={() => setFocus(false)}
          onChange={handleChange}
          onFocus={() => setFocus(true)}
          placeholder={placeholder}
          type="text"
          value={internalValue || value}
        />
        {resetButton && (
          <button
            className={`w-6 border border-x-0 text-iron hover:text-red125 ${
              focus ? "border-iron" : "border-graphite"
            }`}
            onClick={() => {
              setInternalValue("");
              onSearch("");
            }}
            type="button"
          >
            {(internalValue || value).length > 0 && <CrossSmall />}
          </button>
        )}
        <button
          className={`rounded-sm rounded-l-none border border-l-0 bg-red px-4 text-white hover:bg-red125 ${
            focus ? "border-iron" : "border-red hover:border-red125"
          }`}
          onClick={() => onSubmit(internalValue || value)}
          type="button"
        >
          <MagnifyingGlassSmall />
        </button>
      </form>
    </div>
  );
}
