import useI18n from "@/lib/useI18n";

export default function EmptyResultsMessage({ visible }: { visible: boolean }) {
  const { t } = useI18n();
  return visible ? (
    <div className="mx-4 text-granite" data-cy="no-results-message">
      {t("emptyResultsMessage")}
    </div>
  ) : null;
}
