import useI18n from "@/lib/useI18n";

import Pagination from "./sbb/Pagination";

export default function AppPagination({ ...props }) {
  const { t } = useI18n();
  return (
    <Pagination
      nextPageText={t("pagination.nextPage")}
      previousPageText={t("pagination.previousPage")}
      {...props}
    />
  );
}
