import { Combobox } from "@headlessui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, {
  createContext,
  forwardRef,
  Fragment,
  LegacyRef,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ChevronRightSmall, MagnifyingGlassSmall, TickSmall } from "./icons";

const SelectContext = createContext<{ multiple?: boolean }>({});

export declare interface OptionProps {
  children: string;
  selected?: boolean;
  value?: string;
}

export function Option({ children, selected, value }: OptionProps) {
  const { multiple } = useContext(SelectContext);
  return (
    <Combobox.Option as={Fragment} value={value}>
      {(renderProps) => {
        // Workaround to support multiple selection.
        const isSelected = selected || renderProps.selected;

        return (
          <div
            className={`relative flex items-center truncate border-l px-2 py-1 text-iron hover:cursor-pointer hover:border-red125 hover:bg-milk hover:text-red125 ${
              !multiple && isSelected ? "border-iron bg-cloud" : "border-white"
            }`}
          >
            {multiple ? (
              <>
                <input
                  checked={isSelected}
                  className="peer h-5 w-5 appearance-none rounded-sm border border-graphite focus:border-iron disabled:border-aluminum disabled:bg-milk"
                  readOnly
                  type="checkbox"
                />
                <TickSmall className="absolute left-[0.4rem] hidden text-iron peer-checked:block peer-disabled:text-granite" />
                <div className="ml-2">{children}</div>
              </>
            ) : (
              children
            )}
          </div>
        );
      }}
    </Combobox.Option>
  );
}

export declare interface SelectProps {
  children?: React.ReactElement<OptionProps>[];
  disabled?: boolean;
  error?: string;
  filter?: boolean;
  label: string;
  multiple?: boolean;
  onChange?: (value: string) => void;
  onFilterChange?: (value: string) => void;
  pleaseSelectText?: string;
  required?: boolean;
  value?: string;
  valueLabel?: string;
}

declare interface SelectFilterProps {
  onChange: (value: string) => void;
  value: string;
}

function SelectFilter({ onChange, value }: SelectFilterProps) {
  useLayoutEffect(() => onChange(""), [onChange]);

  return (
    <div className="relative m-2">
      <input
        className="block w-full rounded-sm border border-graphite py-1 pl-8 pr-2 outline-none"
        onChange={(event) => {
          event.preventDefault();
          onChange?.(event.target.value);
        }}
        type="text"
        value={value}
      />
      <MagnifyingGlassSmall className="pointer-events-none absolute left-1.5 top-1.5 text-granite" />
    </div>
  );
}

interface SelectListProps {
  children: React.ReactNode;
  options?: React.ReactElement<OptionProps>[];
}

function SelectList({ children, options }: SelectListProps) {
  const listRef = useRef<HTMLUListElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: options?.length || 0,
    estimateSize: () => 32,
    getScrollElement: () => listRef.current,
    overscan: 999,
  });

  return (
    <Combobox.Options
      as="div"
      className="z-30 max-h-96 overflow-x-hidden overflow-y-scroll border border-t-0 border-iron bg-white py-1"
      ref={listRef}
    >
      {children}
      <div
        className="relative w-full"
        style={{ height: `${rowVirtualizer.getTotalSize()}px` }}
      >
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
            <div
              className="absolute left-0 top-0 w-full"
              key={virtualRow.index}
              style={{ transform: `translateY(${virtualRow.start}px)` }}
            >
              {options?.[virtualRow.index]}
            </div>
          ))
        }
      </div>
    </Combobox.Options>
  );
}

function Select(
  {
    children,
    disabled,
    error,
    filter,
    label,
    multiple = false,
    onChange,
    onFilterChange,
    pleaseSelectText = "Please select ...",
    required,
    value,
    valueLabel,
    ...containerProps
  }: SelectProps,
  ref?: LegacyRef<HTMLInputElement>,
) {
  const [filterValue, setFilterValue] = useState("");

  const options = useMemo(() => {
    if (filterValue !== "") {
      return children!.filter((o) =>
        o.props.children.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }
    return children!;
  }, [children, filterValue]);

  const handleFilterChange = useCallback(
    (v: string) => {
      onFilterChange?.(v);
      setFilterValue(v);
    },
    [onFilterChange],
  );

  return (
    <div className="relative" ref={ref} {...containerProps}>
      <SelectContext.Provider value={{ multiple }}>
        <Combobox
          disabled={disabled}
          // TODO: fix our TS definition
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          multiple={multiple}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={onChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={multiple ? [value] : value || ""}
        >
          {({ open }) => (
            <div className="z-10 flex w-full flex-col">
              <Combobox.Label className="text-sm text-granite">
                {label}
              </Combobox.Label>
              <div role="listbox">
                <Combobox.Button
                  className={`group flex w-full space-x-2 rounded-sm border p-2 disabled:border-aluminum disabled:bg-milk disabled:text-granite ${
                    error && !open ? "border-red text-red" : ""
                  } ${
                    open
                      ? "rounded-b-none border-iron border-b-cloud"
                      : "border-graphite"
                  } ${required ? "bg-mandatory" : ""}`}
                >
                  <div className="grow text-left">
                    {valueLabel || value || pleaseSelectText}
                  </div>
                  <ChevronRightSmall
                    className={`transition-transform duration-300 group-enabled:group-hover:text-red125 ${
                      open ? "-rotate-90" : "rotate-90"
                    }`}
                  />
                </Combobox.Button>
                {open && (
                  <div className="absolute left-0 z-20 flex w-full flex-col-reverse">
                    <div className="-mt-1 h-2 rounded-b-sm bg-black opacity-20" />
                    <SelectList options={options}>
                      {filter && (
                        <SelectFilter
                          onChange={handleFilterChange}
                          value={filterValue}
                        />
                      )}
                      {value && !multiple && !required && (
                        <Option value={undefined}>---</Option>
                      )}
                    </SelectList>
                  </div>
                )}
              </div>
            </div>
          )}
        </Combobox>
      </SelectContext.Provider>
      <div className="text-sm text-red">{error}</div>
    </div>
  );
}

export default forwardRef(Select);
