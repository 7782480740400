/**
 * - remove null and empty array from query states object (nuqs)
 * - convert "true" and "false" strings to boolean
 */
export default function transformQS(queryStates: NonNullable<unknown>) {
  return Object.fromEntries(
    Object.entries(queryStates)
      .filter(([, value]) => {
        return Array.isArray(value) ? value.length > 0 : value !== null;
      })
      .map(([key, value]) => {
        if (value === "true") return [key, true];
        if (value === "false") return [key, false];
        return [key, value];
      }),
  );
}
